@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Roboto Slab", serif;
}

body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.1s filter linear;
}

.app {
  background: #fff;
}

.bg-bnb {
  background: #5210eb;
}
.bnb {
  color: #5210eb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(58, 58, 58);
}

.reddish {
  color: #930a02;
}

.greenish {
  color: #1b9f56;
}
/* 
.border-gradient-1 {
  border-top-color: #80ADA0;
} */
/* 
.border-gradient {
  border-top-color: #80ADA0;
} */

.border-reddish {
  border-color: #930a02;
}

.light-border-reddish {
  border-bottom: 1px solid rgb(246, 220, 220);
}

.stat,
#error-target .swal2-popup,
#success-target .swal2-popup {
  border-color: 1px solid rgba(146, 9, 0, 0.2);
  background-color: rgb(255, 238, 238);
  border-radius: 10px;
  border: 1px solid rgba(146, 9, 0, 0.2);
}

#error-target .swal2-popup {
  padding: 15px 30px;
  margin-top: 100px;
  margin-right: 30px;
  background-color: #de2d23;
  color: white !important;
}

#success-target .swal2-popup {
  padding: 15px 30px;
  margin-top: 100px;
  margin-right: 30px;
  background-color: green;
  color: white !important;
}

#error-target .swal2-content,
#success-target .swal2-content {
  color: white !important;
}

.tab {
  /* border-radius: 4px; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.blackish {
  color: rgb(58, 58, 58);
}

.tab.active {
  border: 1.5px solid rgba(146, 9, 0, 0.2);
  background-color: rgb(255, 238, 238);
  border-bottom: 1.5px;
}

.rc-slider-rail {
  background-color: rgb(192, 187, 187) !important;
  height: 12px !important;
}

.rc-slider-track {
  background-color: rgb(213, 61, 51) !important;
  height: 12px !important;
}

.rc-slider-dot {
  width: 20px !important;
  height: 20px !important;
  bottom: -12px !important;
}

.rc-slider-dot.rc-slider-dot-active {
  background-color: rgb(213, 61, 51) !important;
  border: 0.5px solid rgb(213, 61, 51);
}

.rc-slider-dot:last-child {
  left: 99% !important;
}

.rc-slider-handle {
  display: none;
}

.rc-slider-mark {
  top: -40px !important;
}

.rc-slider-mark-text {
  font-weight: 400;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 10px;
}

.rc-slider-mark-text.rc-slider-mark-text-active {
  color: #930a02;
}

.input {
  transition: border 0.2s ease-in-out;
  min-width: 280px;
}

.input:focus + .label,
.input:active + .label,
.input.filled + .label {
  font-size: 0.75rem;
  transition: all 0.2s ease-out;
  top: -0.1rem;
  color: #667eea;
}

.label {
  transition: all 0.2s ease-out;
  top: 0.4rem;
  left: 0;
}

.hih_ref {
  color: rgb(58, 58, 58);
  font-size: 100%;
  vertical-align: baseline;
  line-height: inherit;
  margin-top: 30px;
  background-color: rgb(255, 238, 238);
  border-radius: 10px;
  border: 1px solid rgba(146, 9, 0, 0.2);
  padding: 1.2rem 2rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 50%;
  -webkit-box-pack: center;
  justify-content: center;
}

.ref-content {
  color: rgb(58, 58, 58);
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-size: 100%;
  vertical-align: baseline;
  line-height: inherit;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

#error-target,
#success-target {
  position: relative;
  border-style: solid;
}

.position-absolute {
  position: absolute;
}
